<template>
  <div class="my-loan">
    <v-container class="pa-0 mb-0 mb-md-8 mx-0">
      <div class="card">
        <v-card
          class="transparent px-4 py-3 px-md-6 pt-10 pb-6"
          elevation="0"
          flat
          tile
        >
          <v-row>
            <v-col cols="12">
              <!-- My loan -->
              <h1 class="header-h1">My loan</h1>

              <div class="d-flex flex-column flex-sm-row mt-2">
                <!-- Loan agreement -->
                <h6 class="b-caption">
                  <span class="gray700--text"> Loan agreement </span>
                  <span class="ml-2">
                    No.{{ loan && loan.loan_id ? loan.loan_id : "" }}
                  </span>
                </h6>

                <!-- Loan amount -->
                <h6 class="b-caption ml-sm-6">
                  <span class="gray700--text"> Loan amount </span>
                  <span class="ml-2">
                    R
                    {{
                      loan &&
                      loan.contractual_amount &&
                      typeof loan.contractual_amount === "number"
                        ? loan.contractual_amount
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, "$& ")
                        : ""
                    }}
                  </span>
                </h6>

                <!-- Issue date -->
                <h6 class="b-caption ml-sm-6">
                  <span class="gray700--text"> Issue date </span>
                  <span class="ml-2">
                    {{
                      loan && loan.issue_date
                        ? moment(loan.issue_date).format("DD MMMM YYYY")
                        : ""
                    }}
                  </span>
                </h6>

                <!-- Repayment date -->
                <h6 class="b-caption ml-sm-6">
                  <span class="gray700--text"> Repayment date </span>
                  <span class="ml-2">
                    {{
                      loan && loan.maturity_date
                        ? moment(loan.maturity_date).format("DD MMMM YYYY")
                        : ""
                    }}
                  </span>
                </h6>
              </div>
            </v-col>

            <!-- Error -->
            <InnerCard
              v-if="loan && loan.in_arrears"
              color="error50"
              class="mb-n4 mr-n4"
            >
              <h5 class="header-h5 error500--text">
                Your loan was not repaid on your repayment date and is currently
                overdue
              </h5>

              <p class="b-body-2 error600--text mb-3">
                Remember, non-repayment of a loan is a breach of a contract
                between you and Lime Loans South Africa (Pty) Ltd and could have
                legal consequences including but not limited to arrears interest
                and fees, collection fees, and your overdue information will be
                submitted to the credit bureau which will negatively affect your
                credit profile.
              </p>
            </InnerCard>

            <!-- Contractual amount -->
            <InnerCard>
              <!-- Contractual amount -->
              <p class="b-body-1 gray800--text">Contractual amount</p>

              <h2 class="header-h2 mt-n3 mb-3">
                R
                {{
                  loan &&
                  loan.contractual_amount &&
                  typeof loan.contractual_amount === "number"
                    ? loan.contractual_amount
                        .toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, "$& ")
                    : ""
                }}
              </h2>
            </InnerCard>

            <!-- Repayment options -->
            <v-col cols="12">
              <!-- Repayment options -->
              <h3 class="header-h3">Repayment options</h3>

              <!-- btns -->
              <div class="d-flex flex-column flex-sm-row mt-3">
                <!-- DebitOrder -->
                <div>
                  <!-- Desktop -->
                  <!-- Normal -->
                  <div v-if="loan && !loan.in_arrears" class="hidden-xs-only">
                    <div
                      @click="changeTab('debitOrderIsActive')"
                      class="d-flex align-center justify-center pointer"
                      :class="
                        debitOrderIsActive ? 'custom-btn-active' : 'custom-btn'
                      "
                    >
                      <h6 class="btn-text">DebitOrder</h6>
                    </div>
                  </div>

                  <!-- Disabled -->
                  <div v-else class="hidden-xs-only">
                    <div
                      class="d-flex align-center justify-center custom-btn-disable"
                    >
                      <h6 class="btn-text">DebitOrder</h6>
                    </div>
                  </div>

                  <!-- Mobile -->
                  <!-- Normal -->
                  <div v-if="loan && !loan.in_arrears" class="hidden-sm-and-up">
                    <div
                      @click="changeTab('debitOrderIsActive')"
                      class="d-flex align-center justify-center pointer"
                      :class="
                        debitOrderIsActive
                          ? 'custom-btn-active-mobile'
                          : 'custom-btn-mobile'
                      "
                    >
                      <h6 class="btn-text">DebitOrder</h6>
                    </div>
                  </div>

                  <!-- Disabled -->
                  <div v-else class="hidden-sm-and-up">
                    <div
                      class="d-flex align-center justify-center custom-btn-disable-mobile"
                    >
                      <h6 class="btn-text">DebitOrder</h6>
                    </div>
                  </div>
                </div>

                <!-- Normal EFT -->
                <div>
                  <!-- Desktop -->
                  <div class="hidden-xs-only ml-5">
                    <div
                      @click="changeTab('normalEFTIsActive')"
                      class="d-flex align-center justify-center pointer"
                      :class="
                        normalEFTIsActive ? 'custom-btn-active' : 'custom-btn'
                      "
                    >
                      <h6 class="btn-text">Normal EFT</h6>
                    </div>
                  </div>

                  <!-- Mobile -->
                  <div class="hidden-sm-and-up">
                    <div
                      @click="changeTab('normalEFTIsActive')"
                      class="d-flex align-center justify-center pointer mt-2"
                      :class="
                        normalEFTIsActive
                          ? 'custom-btn-active-mobile'
                          : 'custom-btn-mobile'
                      "
                    >
                      <h6 class="btn-text">Normal EFT</h6>
                    </div>
                  </div>
                </div>
              </div>

              <!-- data -->
              <div class="mt-5">
                <!-- debitOrderIsActive -->
                <div v-if="debitOrderIsActive">
                  <p class="b-body-1">
                    Your DebiCheck mandate was successfully aproved.
                  </p>
                </div>

                <!-- normalEFTIsActive -->
                <div v-else>
                  <p class="b-body-1">
                    You can made payment in your mobile bank application. In
                    order to repay the loan please follow the instruction:
                  </p>

                  <div class="ml-2">
                    <!-- 1. -->
                    <div class="d-flex">
                      <p class="b-body-1">1.</p>

                      <p class="b-body-1 ml-1">
                        Download your settlement quotation
                      </p>
                    </div>

                    <!-- 2. -->
                    <div class="d-flex">
                      <p class="b-body-1">2.</p>

                      <p class="b-body-1 ml-1">
                        Go to your banking app or online banking website
                      </p>
                    </div>

                    <!-- 3. -->
                    <div class="d-flex">
                      <p class="b-body-1">3.</p>

                      <p class="b-body-1 ml-1">
                        Make a payment of your settlement amount using the
                        following banking details:
                      </p>
                    </div>

                    <v-card
                      class="transparent mt-n2 ml-3"
                      width="392"
                      tile
                      flat
                    >
                      <v-row class="ma-0 pa-0">
                        <!-- Bank -->
                        <v-card class="transparent" width="130" tile flat>
                          <p class="ma-0 pa-0">Bank</p>
                        </v-card>
                        <v-card
                          class="transparent ml-sm-6"
                          width="238"
                          tile
                          flat
                        >
                          FNB
                        </v-card>

                        <v-card
                          color="gray300 mt-1 mb-2"
                          width="100%"
                          height="1"
                          tile
                          flat
                        >
                        </v-card>

                        <!-- Account number -->
                        <v-card class="transparent" width="130" tile flat>
                          <p class="ma-0 pa-0">Account number</p>
                        </v-card>
                        <v-card
                          class="transparent ml-sm-6"
                          width="238"
                          tile
                          flat
                        >
                          62618403175
                        </v-card>

                        <v-card
                          color="gray300 mt-1 mb-2"
                          width="100%"
                          height="1"
                          tile
                          flat
                        >
                        </v-card>

                        <!-- Branch code -->
                        <v-card class="transparent" width="130" tile flat>
                          <p class="ma-0 pa-0">Branch code</p>
                        </v-card>
                        <v-card
                          class="transparent ml-sm-6"
                          width="238"
                          tile
                          flat
                        >
                          251 542
                        </v-card>

                        <v-card
                          color="gray300 mt-1  mb-2"
                          width="100%"
                          height="1"
                          tile
                          flat
                        >
                        </v-card>

                        <!-- Account name -->
                        <v-card class="transparent" width="130" tile flat>
                          <p class="ma-0 pa-0">Account name</p>
                        </v-card>
                        <v-card
                          class="transparent ml-sm-6"
                          width="238"
                          tile
                          flat
                        >
                          Lime Loans South Africa (Pty) ltd
                        </v-card>

                        <v-card
                          color="gray300 mt-1  mb-2"
                          width="100%"
                          height="1"
                          tile
                          flat
                        >
                        </v-card>

                        <!-- Reference -->
                        <v-card class="transparent" width="130" tile flat>
                          <p class="ma-0 pa-0">Reference</p>
                        </v-card>
                        <v-card
                          class="transparent ml-sm-6"
                          width="238"
                          tile
                          flat
                        >
                          {{ loan && loan.loan_id ? loan.loan_id : "" }}
                        </v-card>

                        <v-card
                          color="gray300 mt-1  mb-3"
                          width="100%"
                          height="1"
                          tile
                          flat
                        >
                        </v-card>
                      </v-row>
                    </v-card>

                    <!-- 4. -->
                    <div class="d-flex">
                      <p class="b-body-1">4.</p>

                      <p class="b-body-1 ml-1">
                        Send us the proof of payment via the following email
                        collections@lime24.co.za. Please put your ID number as
                        the subject of the email. The payment will be receipted
                        once appearing in Lime account. This usually takes
                        approximately 24–48 hours
                      </p>
                    </div>

                    <!-- 5. -->
                    <div class="d-flex">
                      <p class="b-body-1">5.</p>

                      <p class="b-body-1 ml-1">
                        The settlement amount will remain valid for 7 days
                      </p>
                    </div>
                  </div>

                  <!-- Settlement quotation -->
                  <div
                    @click="getSettlementQuoteDocument()"
                    class="d-flex align-center mb-4 pointer"
                  >
                    <v-img
                      alt="arrow-to-botton"
                      class="arrow-to-botton mr-2"
                      src="@/assets/img/dashboard/pages/my-loan/arrow-to-botton.svg"
                      max-width="24"
                      contain
                    />

                    <p
                      class="b-body-1 primary--text font-weight-bold ma-0 pa-0"
                    >
                      Settlement quotation
                    </p>
                  </div>

                  <p class="b-body-1">
                    If any help is needed please contact us via
                    <a
                      class="gray900--text ma-0 pa-0"
                      href="mailto:support@lime24.co.za"
                      target="_blank"
                    >
                      support@lime24.co.za
                    </a>
                    or our support number +27104426722
                  </p>
                </div>
              </div>
            </v-col>

            <!-- Documents -->
            <v-col class="mt-n5" cols="12">
              <!-- Documents -->
              <h3 class="header-h3 mb-2">Documents</h3>

              <!-- Loan agreement -->
              <a
                class="b-text primary--text text-decoration-none"
                @click="getSignedAgreementDocument()"
              >
                Loan agreement
              </a>

              <br />

              <!-- Loan balance -->
              <a
                class="b-text primary--text text-decoration-none mt-1"
                @click="getStatementDocument"
              >
                Loan balance
              </a>
            </v-col>
          </v-row>
        </v-card>

        <!-- Overlay -->
        <v-overlay :value="overlay" z-index="1000">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import InnerCard from "@/components/shared/cards/InnerCard";

export default {
  components: { InnerCard },

  name: "LimeLoansMyLoan",

  data() {
    return {
      overlay: false,

      debitOrderIsActive: true,
      normalEFTIsActive: false,
      error: true,
    };
  },

  computed: {
    ...mapState({
      auth: "auth",
      retrieveAttachment: "retrieveAttachment",
      generateSettlementQuote: "generateSettlementQuote",
      generateStatement: "generateStatement",
    }),

    // loan
    loan() {
      if (
        this.auth &&
        this.auth.user &&
        this.auth.user.loans &&
        this.auth.user.loans.length > 0
      ) {
        return this.auth.user.loans[0];
      }

      return null;
    },
  },

  created() {
    if (
      this.auth &&
      this.auth.user &&
      this.auth.user.loans &&
      this.auth.user.loans.length > 0 &&
      this.auth.user.loans[0].in_arrears
    ) {
      this.debitOrderIsActive = false;
      this.normalEFTIsActive = true;
    } else {
      this.debitOrderIsActive = true;
      this.normalEFTIsActive = false;
    }

    this.$helpers.resetErrorHandlerState();
  },

  mounted() {},

  methods: {
    ...mapActions({
      getApiSignedAgreementDocument:
        "retrieveAttachment/getApiSignedAgreementDocument",

      getApiSettlementQuoteDocument:
        "generateSettlementQuote/getApiSettlementQuoteDocument",

      getApiStatementDocument: "generateStatement/getApiStatementDocument",

      getApiUserDetails: "auth/getApiUserDetails",

      logOutUser: "auth/logOutUser",
    }),

    // changeTab
    changeTab(tab) {
      if (tab == "debitOrderIsActive") {
        this.debitOrderIsActive = true;
        this.normalEFTIsActive = false;
      } else {
        this.debitOrderIsActive = false;
        this.normalEFTIsActive = true;
      }
    },

    // getSignedAgreementDocument
    async getSignedAgreementDocument() {
      this.overlay = true;

      if (this.retrieveAttachment.signedAgreementDocumentData == "") {
        const signedAgreementDocumentRes =
          await this.getApiSignedAgreementDocument();

        if (signedAgreementDocumentRes.status == 200) {
          this.downloadSignedAgreementDocument();
        } else if (signedAgreementDocumentRes.status == 401) {
          this.logOutUser();
        } else if (signedAgreementDocumentRes.status == 422) {
          const data = signedAgreementDocumentRes.data;

          // idnumber
          if ("idnumber" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.idnumber[0],
            });
          }

          // id_number
          if ("id_number" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.id_number[0],
            });
          }

          // loan_id
          if ("loan_id" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.loan_id[0],
            });
          }
        } else {
          this.$helpers.handleError(
            signedAgreementDocumentRes.status,
            signedAgreementDocumentRes.data
          );
        }
      } else {
        this.downloadSignedAgreementDocument();
      }

      this.overlay = false;
    },

    // getStatementDocument
    async getStatementDocument() {
      this.overlay = true;

      if (this.generateStatement.statementDocumentData == "") {
        const statementDocumentRes = await this.getApiStatementDocument();

        if (statementDocumentRes.status == 200) {
          this.downloadStatementDocument();
        } else if (statementDocumentRes.status == 401) {
          this.logOutUser();
        } else if (statementDocumentRes.status == 422) {
          const data = statementDocumentRes.data;

          // idnumber
          if ("idnumber" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.idnumber[0],
            });
          }

          // id_number
          if ("id_number" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.id_number[0],
            });
          }
        } else {
          this.$helpers.handleError(
            statementDocumentRes.status,
            statementDocumentRes.data
          );
        }
      } else {
        this.downloadStatementDocument();
      }

      this.overlay = false;
    },

    // getSettlementQuoteDocument
    async getSettlementQuoteDocument() {
      this.overlay = true;

      if (this.generateSettlementQuote.settlementQuoteDocumentData == "") {
        const settlementQuoteDocumentRes =
          await this.getApiSettlementQuoteDocument();

        if (settlementQuoteDocumentRes.status == 200) {
          this.downloadSettlementQuoteDocument();
        } else if (settlementQuoteDocumentRes.status == 401) {
          this.logOutUser();
        } else if (settlementQuoteDocumentRes.status == 422) {
          const data = settlementQuoteDocumentRes.data;

          // idnumber
          if ("idnumber" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.idnumber[0],
            });
          }

          // id_number
          if ("id_number" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.id_number[0],
            });
          }
        } else {
          this.$helpers.handleError(
            settlementQuoteDocumentRes.status,
            settlementQuoteDocumentRes.data
          );
        }
      } else {
        this.downloadSettlementQuoteDocument();
      }

      this.overlay = false;
    },

    // downloadSignedAgreementDocument
    downloadSignedAgreementDocument() {
      this.overlay = true;

      this.$helpers.downloadBase64PDF(
        this.retrieveAttachment.signedAgreementDocumentData,
        `Signed loan agreement`
      );

      this.overlay = false;
    },

    // downloadStatementDocument
    downloadStatementDocument() {
      this.overlay = true;

      this.$helpers.downloadBase64PDF(
        this.generateStatement.statementDocumentData,
        `Loan balance`
      );

      this.overlay = false;
    },

    // downloadSettlementQuoteDocument
    downloadSettlementQuoteDocument() {
      this.overlay = true;

      this.$helpers.downloadBase64PDF(
        this.generateSettlementQuote.settlementQuoteDocumentData,
        `Settlement quotation`
      );

      this.overlay = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-btn,
.custom-btn-mobile,
.custom-btn-active,
.custom-btn-active-mobile,
.custom-btn-disable,
.custom-btn-disable-mobile {
  background: #ffffff;
  border-radius: 4px;

  width: 180px;
  height: 48px;
}

.custom-btn,
.custom-btn-mobile {
  box-shadow: 0px 0px 8px rgba(61, 61, 61, 0.12),
    0px 2px 4px rgba(61, 61, 61, 0.06);
}

.custom-btn-active,
.custom-btn-active-mobile {
  /* Primary Lime/500 */
  border: 2px solid #5d9922;
}

.custom-btn-disable,
.custom-btn-disable-mobile {
  background: #f5f5f5;
  border-radius: 4px;
}

.custom-btn-mobile,
.custom-btn-active-mobile,
.custom-btn-disable-mobile {
  width: 100%;
}

.btn-text {
  //styleName: Header mobile/H5;
  font-family: "Raleway";
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0px;
}
</style>
