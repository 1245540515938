<template>
  <div class="no-loan">
    <v-container class="pa-0 mb-0 mb-md-8">
      <div class="card">
        <v-card
          class="transparent px-4 py-3 px-md-6 pt-10 pb-6"
          elevation="0"
          flat
          tile
        >
          <v-row>
            <!-- Main -->
            <v-col cols="12">
              <!-- Your next loan is waiting for you -->
              <h1 class="header-h1">Your next loan is waiting for you</h1>
            </v-col>

            <!-- Left -->
            <v-col cols="12" sm="6" md="8">
              <p class="b-body-1 mt-1">
                Please check your
                <router-link
                  class="b-body-1"
                  :to="{ name: 'DashboardPersonalDataPage' }"
                >
                  personal data
                </router-link>
                before submitting your application.
              </p>

              <!-- Apply for loan -->
              <!-- Desktop -->
              <div class="hidden-xs-only">
                <v-btn
                  :to="{ name: 'CreateLoanApplicationPage' }"
                  class="b-button primary text-capitalize px-8"
                  height="48"
                  elevation="0"
                >
                  Apply for loan
                </v-btn>
              </div>

              <!-- Mobile -->
              <div class="hidden-sm-and-up">
                <v-btn
                  :to="{ name: 'CreateLoanApplicationPage' }"
                  class="b-button primary text-capitalize"
                  height="48"
                  block
                  elevation="0"
                >
                  Apply for loan
                </v-btn>
              </div>
            </v-col>

            <!-- Right -->
            <v-col cols="12" sm="6" md="4">
              <v-img
                alt="Completed"
                class="completed"
                contain
                src="@/assets/img/public/pages/registration/completed.svg"
                max-width="288"
              />
            </v-col>
          </v-row>
        </v-card>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "LimeLoansNoLoan",

  data() {
    return {};
  },

  mounted() {},

  created() {
    // Get User Details After 1 Second
    setTimeout(() => {
      // getUserDetails
      this.getUserDetails();
    }, 1000);
  },

  methods: {
    ...mapActions({
      getApiUserDetails: "auth/getApiUserDetails",
    }),

    // getUserDetails
    async getUserDetails() {
      await this.getApiUserDetails();
    },
  },
};
</script>

<style lang="scss" scoped>
//
</style>
