<template>
  <div>
    <div v-if="current === 'MyLoan'">
      <MyLoan />
    </div>
    <div v-else-if="current === 'NoLoan'">
      <NoLoan />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import MyLoan from "@/components/dashboard/pages/my-loan/MyLoan";
import NoLoan from "@/components/dashboard/pages/my-loan/NoLoan";

export default {
  name: "LimeLoansMain",
  components: { MyLoan, NoLoan },

  data() {
    return {
      current: "",
    };
  },

  computed: {
    ...mapState({ auth: "auth" }),
  },

  created() {
    // has_open_application

    // this.current = "NoLoan";
    if (
      this.auth &&
      this.auth.user &&
      this.auth.user.loans &&
      this.auth.user.loans.length > 0
    ) {
      this.current = "MyLoan";
    } else if (
      this.auth.user.negative_classification ||
      !this.auth.user.can_apply
    ) {
      this.$router.push({ name: "DashboardRefusalPage" });
    } else if (this.auth.user.open_application.can_sign_agreement) {
      this.$router.push({ name: "DashboardSigningLoanAgreementPage" });
    } else if (this.auth.user.has_open_application) {
      // has_arrears
      if (this.auth.user.has_arrears) {
        this.current = "MyLoan";
      } else if (this.auth.user.open_application.has_truid) {
        // this.current = "WaitingForApproval";

        this.$router.push({ name: "DashboardWaitingForApprovalPage" });
      } else if (this.auth.user.open_application.has_bank_statement) {
        // this.current = "WaitingForApproval";

        this.$router.push({ name: "DashboardWaitingForApprovalPage" });
      } else if (!this.auth.user.open_application.has_bank_statement) {
        this.$router.push({ name: "CreateLoanApplicationPage" });
      }
      // else if (!this.auth.user.open_application.has_payslip) {
      //   this.$router.push({ name: "CreateLoanApplicationPage" });
      // }
      else {
        // this.current = "WaitingForApproval";
        this.$router.push({ name: "DashboardWaitingForApprovalPage" });
      }
    } else {
      this.current = "NoLoan";
    }
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
//
</style>
