<template>
  <div class="my-loan-page">
    <Main />
  </div>
</template>

<script>
import { mapState } from "vuex";

import Main from "@/components/dashboard/pages/my-loan/Main";

export default {
  name: "LimeLoansMyloan",

  components: { Main },

  metaInfo: {
    meta: [
      {
        name: "facebook-domain-verification",
        content: process.env.VUE_APP_FACEBOOK_DOMAIN_VERIFICATION,
      },
    ],
  },

  data() {
    return {
      current: "",
    };
  },

  computed: {
    ...mapState({ auth: "auth" }),
  },

  created() {
    if (
      this.auth &&
      this.auth.user &&
      this.auth.user.banking_details &&
      this.auth.user.banking_details.bank_name &&
      this.auth.user.banking_details.account_number &&
      this.auth.user.banking_details.bank_name == "Standard Bank" &&
      this.auth.user.banking_details.account_number == "123456789"
    ) {
      //
    } else if (
      !this.auth.user.has_banking &&
      !this.auth.user.has_employment &&
      !this.auth.user.has_next_of_kin
    ) {
      this.$router.push({ name: "RegistrationPage" });
    }
    // has_open_application
    /* if (this.auth.user.has_open_application) {
      // has_arrears
      if (this.auth.user.has_arrears) {
        this.current = "MyLoan";
      } else if (this.auth.user.open_application.has_truid) {
        this.current = "WaitingForApproval";
      } else if (!this.auth.user.open_application.has_bank_statement) {
        this.current = "NoLoan";
      } else {
        this.current = "WaitingForApproval";
      }
    } else {
      this.current = "NoLoan";
    } */
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
//
</style>
